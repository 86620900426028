export enum enEvent {
  ADD_BOX = 'addBox',
  NOT_CONFIRMED = 'notConfirmed',
  CONFIRMATION = 'confirmation',
  SKIPPED = 'skipped',
  BOX_ERROR = 'boxError',
  CONVEYOR_BELD_STATUS = 'conveyorBeltStatus',
  CONVEYOR_BELD_CLIENT_STATUS = 'conveyorBeldClientStatus',
  CONVEYOR_BELD_ENABLED = 'conveyorBeltEnabled',
  CONVEYOR_BELD_DISABLED = 'conveyorBeltDisabled',
  JOIN_ROOM = 'joinRoom',
  RESCHEDULED = 'rescheduled',
  CANCELED = 'canceled',
  ROUTE_ERROR = 'routeError',
  ROUTE_ERROR_RESOLVED = 'routeErrorConfirmed',
  ROUTE_ERROR_NOT_RESOLVED = 'routeErrorNotConfirmed',
  ROUTE_ERROR_WRONG_ORDER = 'routeErrorWrongOrder',
  PENDING_ORDER = 'pendingOrder',
  PENDING_INVOICE_ORDER = 'pendingInvoiceOrder',
  PENDING_CONFERENCE_ORDER = 'pendingConferenceOrder',
  PENDING_FINANCE_VALIDATE_ORDER = 'pendingFinanceValidateOrder',
  ROUTE_ABOVE_BOX_NO_TRATAMENT = 'routeAboveBoxNoTratament',
  ROUTE_ABOVE_BOX_NO_TRATAMENT_RESOLVED = 'routeAboveBoxNoTratamentResolved',
  READ_TAG = 'readTag',
  PENDING_ORDER_RESOLVED = 'pendingOrderResolved',
  PENDING_INVOICE_ORDER_RESOLVED = 'pendingOrderInvoiceResolved',
  PENDING_CONFERENCE_ORDER_RESOLVED = 'pendingConferenceOrderResolved',
  PENDING_FINANCE_VALIDATE_ORDER_RESOLVED = 'pendingFinanceValidateOrderResolved',
  TRUCK_LOADING_ERROR = 'truckLoadingError',
}
