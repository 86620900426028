import { api } from './api'

export interface ITruckLoading {
  id: number
  license_plate: string
  start_time: string
  end_time: string | null
  message?: string
}

export class TruckLoadingService {
  async start(license_plate: string): Promise<ITruckLoading> {
    return await api.post(carambolaUrl + '/loading-conference/truck/start/', { license_plate })
  }

  async finish(license_plate: string): Promise<ITruckLoading> {
    return await api.post(carambolaUrl + '/loading-conference/truck/finish/', { license_plate })
  }

  async getStatus(): Promise<ITruckLoading> {
    return await api.get(carambolaUrl + '/loading-conference/truck/status/')
  }
}

const carambolaUrl = process.env.REACT_APP_API_CARAMBOLA
export const truckLoadingService = new TruckLoadingService()
