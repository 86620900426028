import { Tab, Tabs, Box, Typography, Divider, FormControlLabel, styled } from '@material-ui/core'
import { Button } from 'components'
import { usePopup } from 'hooks/usePopup'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { ChangeEvent, useState } from 'react'
import { loadingConference } from 'services/loadingConferenceService'
import { Checkbox, Modal } from 'shared'

import { useStyles } from './styles'

interface IProps {
  isOpen: boolean
  handleCloseModal: () => void
  selectedDate: Date
}

const LabelStyle = styled(Typography)({
  color: '#353535',
  textAlign: 'left',
  userSelect: 'none',
  fontSize: '1.1rem',
  '& span': {
    display: 'block',
    color: '#757575',
    fontSize: '0.95rem',
  },
})

export const DownloadExcel = ({ isOpen, handleCloseModal, selectedDate }: IProps) => {
  const classes = useStyles()
  const { addPopup } = usePopup()
  const [disregardConveyorBelt, setDisregardConveyorBelt] = useState(false)
  const [disregardConference, setDisregardConference] = useState(false)
  const [tab, setTab] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  const handleDownloadExcelCountBoxes = async () => {
    setIsLoading(true)
    try {
      const formattedDate = moment(selectedDate).format('YYYY-MM-DD')
      const data = await loadingConference.downloadExcelCountBoxes(
        disregardConveyorBelt,
        disregardConference,
        formattedDate,
      )
      fileDownload(data, `Contagem_de_caixas_${formattedDate}.xls`)
      handleCloseModal()

      addPopup({
        type: 'success',
        title: 'Opa, o download de contagem de caixas foi solicitado com sucesso 🤠',
        description: 'E esse copão aí?!',
      })
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao tentar fazer o download 😢',
        description: 'Dá um alô pro time de tech',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDownloadExcelSeparationPrepare = async () => {
    setIsLoading(true)
    try {
      const formattedDate = moment(selectedDate).format('YYYY-MM-DD')
      const data = await loadingConference.downloadExcelSeparationPrepare(formattedDate)

      fileDownload(data, `preparar_separacao_${formattedDate}.xls`)
      handleCloseModal()

      addPopup({
        type: 'success',
        title: 'Opa, o download do preparar separação foi solicitado com sucesso! 🤠',
      })
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao tentar fazer o download 😢',
        description: 'Dá um alô pro time de tech',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDownloadExcelConsolidateRoutes = async () => {
    setIsLoading(true)
    try {
      const formattedDate = moment(selectedDate).format('YYYY-MM-DD')
      const data = await loadingConference.downloadExcelConsolidateRoutes(formattedDate)

      fileDownload(data, `Rotas_consolidadas_${formattedDate}.xls`)
      handleCloseModal()

      addPopup({
        type: 'success',
        title: 'Opa, o download de rotas consolidadas foi solicitado com sucesso! 🤠',
      })
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao tentar fazer o download 😢',
        description: 'Dá um alô pro time de tech',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDownloadExcelRoutesResume = async () => {
    setIsLoading(true)
    try {
      const formattedDate = moment(selectedDate).format('YYYY-MM-DD')
      const data = await loadingConference.downloadExcelRoutesResume(formattedDate)

      fileDownload(data, `Resumo_de_rotas_${formattedDate}.xls`)
      handleCloseModal()

      addPopup({
        type: 'success',
        title: 'Opa, o download de resumo de rotas foi solicitado com sucesso! 🤠',
      })
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao tentar fazer o download 😢',
        description: 'Dá um alô pro time de tech',
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Modal title="Baixar uma planilha? 🤠" handleClose={handleCloseModal} open={isOpen}>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        centered
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Contagem de caixas" />
        <Tab label="Preparar separação" />
        <Tab label="Rotas consolidadas" />
        <Tab label="Resumo de rotas" />
      </Tabs>
      <Divider style={{ margin: '1rem 0' }} />
      {tab === 0 && (
        <Box>
          <Typography className={classes.subTitle}>
            Quer desconsiderar a contagem em algum dos nossos processos? É só marcar.
          </Typography>
          <Box mt="2rem" ml="1rem">
            {' '}
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              <li style={{ marginBottom: '1rem' }}>
                <FormControlLabel
                  label={<LabelStyle>📦 Sim, a esteira</LabelStyle>}
                  control={
                    <Checkbox
                      color="primary"
                      checked={disregardConveyorBelt}
                      onChange={e => setDisregardConveyorBelt(e.target.checked)}
                      disabled={isLoading}
                    />
                  }
                />
              </li>
              <li>
                <FormControlLabel
                  label={<LabelStyle>🔎 Sim, a conferência</LabelStyle>}
                  control={
                    <Checkbox
                      color="primary"
                      checked={disregardConference}
                      onChange={e => setDisregardConference(e.target.checked)}
                      disabled={isLoading}
                    />
                  }
                />
              </li>
            </ul>
          </Box>
          <Button
            variant="contained"
            className={classes.buttonConfigs}
            onClick={handleDownloadExcelCountBoxes}
            disabled={isLoading}
          >
            <Typography className={classes.buttonText}>
              {isLoading ? 'Baixando...' : 'Pode baixar!'}
            </Typography>
          </Button>
        </Box>
      )}
      {tab === 1 && (
        <Box>
          <Typography className={classes.subTitle}>
            Opa, aqui num tem segredo, é só baixar!
          </Typography>
          <Button
            variant="contained"
            className={classes.buttonConfigs}
            onClick={handleDownloadExcelSeparationPrepare}
            disabled={isLoading}
          >
            <Typography className={classes.buttonText}>
              {isLoading ? 'Baixando...' : 'Pode baixar!'}
            </Typography>
          </Button>
        </Box>
      )}
      {tab === 2 && (
        <Box>
          <Typography className={classes.subTitle}>
            Opa, lembrando que aqui só consideramos pedidos de São Paulo.
          </Typography>
          <Button
            variant="contained"
            className={classes.buttonConfigs}
            onClick={handleDownloadExcelConsolidateRoutes}
            disabled={isLoading}
          >
            <Typography className={classes.buttonText}>
              {isLoading ? 'Baixando...' : 'Pode baixar!'}
            </Typography>
          </Button>
        </Box>
      )}
      {tab === 3 && (
        <Box>
          <Typography className={classes.subTitle}>
            Opa, lembrando que aqui só consideramos pedidos de Sâo Paulo.
          </Typography>
          <Button
            variant="contained"
            className={classes.buttonConfigs}
            onClick={handleDownloadExcelRoutesResume}
            disabled={isLoading}
          >
            <Typography className={classes.buttonText}>
              {isLoading ? 'Baixando...' : 'Pode baixar!'}
            </Typography>
          </Button>
        </Box>
      )}
    </Modal>
  )
}
