import { Player } from '@lottiefiles/react-lottie-player'
import { Box } from '@material-ui/core'
import gifJson from 'assets/lottiefiles/Lottie Lego.json'
import { useRef, useEffect, memo } from 'react'
import { mapTagColor } from 'utils/mapTagColor'

import CardError from '../CardError'
import { Card as CardStyled, InfoCustomer, Section } from '../styles'
import { ColorRoute, Route, TextConferred, useStyles } from './styles'

import { LinearProgress } from 'shared'

import { IOrdersInfo } from '..'

interface IProps {
  className?: string
  currentOrder: IOrdersInfo
  showOrders: IOrdersInfo[]
}

const CardComponent = (props: IProps) => {
  const { className, currentOrder } = props
  const classes = useStyles()

  const routeColor = mapTagColor(currentOrder.color_route)

  const {
    corrected_boxes_number,
    skipped,
    loading_boxes_number,
    conference_boxes_number,
    boxes_by_conferencer,
    adjusted_boxes_number,
    img_base64,
  } = currentOrder

  const conferred_boxes = adjusted_boxes_number || boxes_by_conferencer || conference_boxes_number

  return (
    <>
      <CardStyled className={className}>
        <ColorRoute color={routeColor} />
        <Box
          style={{
            padding: '1rem',
          }}
        >
          <Route>Rota {currentOrder.route}</Route>
          <InfoCustomer>
            <strong>Cliente: </strong>
            <span>{currentOrder.client_name_or_trade}</span>
          </InfoCustomer>
          <InfoCustomer style={{ borderBottom: '1px solid #C4C4C4', paddingBottom: '5px' }}>
            <strong>Loja: </strong>
            <span>{currentOrder.clientType}</span>
          </InfoCustomer>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <InfoCustomer>
                <strong>Pedido: </strong>
                <span className={classes.sizeText} style={{ lineHeight: '3.5rem' }}>
                  #{currentOrder.order_id}
                </span>
              </InfoCustomer>
              <InfoCustomer style={{ lineHeight: '3.5rem' }}>
                <strong>Caixa: </strong>
                <span className={classes.sizeText}>
                  {skipped ? corrected_boxes_number : loading_boxes_number}
                </span>
              </InfoCustomer>
            </Box>
            {(loading_boxes_number === conferred_boxes || skipped) && (
              <TextConferred>Conferido</TextConferred>
            )}
          </Box>
          {img_base64 && (
            <img
              src={img_base64}
              height="200px"
              style={{
                margin: '2rem auto',
                marginBottom: '0px',
                display: 'block',
              }}
            />
          )}
          <Section>
            <CardError typeError={currentOrder.typeError} />
          </Section>
        </Box>
      </CardStyled>
    </>
  )
}

const Card = memo(CardComponent)

export default Card
