import { enEvent } from 'interfaces/enEvent'
import cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { useEffect, useMemo, useRef, useState } from 'react'
import { carambolaWebSocket } from 'settings'
import { io, Socket } from 'socket.io-client'

import { IDataAlertConferenceOrder } from './ModalAlertConferenceOrder'
import { IDataAlertFinanceValidadeOrder } from './modalAlertFinanceValidateOrder'
import { IDataAlertInvoiceOrder } from './ModalAlertInvoiceOrder'
import { IDataAlertOrder } from './ModalAlertOrder'
import { IModalError } from './ModalError'
import { IDataAlertRouteNotTratament } from './ModalRouteNotTratament'

const initialStateModalAlertOrder = {
  open: false,
  data: {
    title: '',
    subtitle: '',
    order_id: '',
    client_name: '',
    separator_name: '',
  },
}

const initialStateModalAlertInvoiceOrder = {
  open: false,
  data: {
    title: '',
    subtitle: '',
    order_id: '',
    client_name: '',
  },
}

const initialStateModalAlertFinanceValidateOrder = {
  open: false,
  data: {
    title: '',
    subtitle: '',
    order_id: '',
    client_name: '',
  },
}

const initialStateModalAlertConferenceOrder = {
  open: false,
  data: {
    title: '',
    subtitle: '',
    order_id: '',
    checker_name: '',
  },
}

export const useSocketLoadingConferece = () => {
  const token = cookies.get('authToken')
  const socket = useMemo<Socket>(
    () =>
      io(carambolaWebSocket as string, {
        transports: ['websocket'],
        auth: { token: `Bearer ${token}` },
      }),
    [],
  )
  const [isConnected, setIsConnected] = useState(false)
  const modalErrorRef = useRef<IModalError>(null)

  const [conveyorBeltStatus, setConveyorBeltStatus] = useState(false)
  const [conveyorBeltClientStatus, setConveyorBeltClientStatus] = useState(false)
  const [userId] = useState(() => jwtDecode<{ user_id: number }>(token as string)?.user_id)
  const [modalAlertOrder, setModalAlertOrder] = useState(initialStateModalAlertOrder)
  const [modalAlertInvoiceOrder, setModalAlertInvoiceOrder] = useState(
    initialStateModalAlertInvoiceOrder,
  )
  const [modalAlertFinanceValidateOrder, setModalAlertFinanceValidateOrder] = useState(
    initialStateModalAlertFinanceValidateOrder,
  )
  const [modalAlertConferenceOrder, setModalAlertConferenceOrder] = useState(
    initialStateModalAlertConferenceOrder,
  )
  const [modalRouteNotTratament, setModalRouteNotTratament] = useState({
    data: {} as IDataAlertRouteNotTratament,
    open: false,
  })

  const confirmOrderBox = (QRcode: string) => {
    socket.emit(enEvent.READ_TAG, { tag_value: QRcode })
  }

  const confirmBoxesQuantity = async (data: { orderId: string; boxesNumber: number }) => {
    socket.emit(enEvent.SKIPPED, { ...data, userId })
  }

  const handleConveyorBeltStatus = (value: boolean) => {
    socket.emit(enEvent.CONVEYOR_BELD_STATUS, value)
  }

  const tratedRoute = () => {
    socket.emit(enEvent.ROUTE_ABOVE_BOX_NO_TRATAMENT_RESOLVED, {
      routeId: modalRouteNotTratament.data.route,
    })
    setModalRouteNotTratament({ data: {} as IDataAlertRouteNotTratament, open: false })
  }

  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true)
      socket.emit('joinRoom', { room: 'frontend' })
    })
    socket.on('disconnect', () => {
      setIsConnected(false)
      setConveyorBeltClientStatus(false)
    })
    socket.on(enEvent.BOX_ERROR, (data: any) => {
      modalErrorRef.current?.handleChangeOrderId({
        orderId: data.order.orderId,
        title: data.message,
      })
    })
    socket.on(enEvent.CONVEYOR_BELD_STATUS, (data: any) => {
      setConveyorBeltStatus(data.is_enabled)
    })
    socket.on(enEvent.CONVEYOR_BELD_CLIENT_STATUS, (data: any) => {
      setConveyorBeltClientStatus(data)
    })

    socket.on(enEvent.ROUTE_ABOVE_BOX_NO_TRATAMENT, (data: IDataAlertRouteNotTratament) => {
      setModalRouteNotTratament({ data, open: true })
    })

    socket.on(enEvent.PENDING_INVOICE_ORDER, (data: IDataAlertInvoiceOrder) => {
      setModalAlertInvoiceOrder({
        data,
        open: true,
      })
    })

    socket.on(enEvent.PENDING_INVOICE_ORDER_RESOLVED, (data: any) => {
      setModalAlertInvoiceOrder(initialStateModalAlertInvoiceOrder)
    })

    socket.on(enEvent.PENDING_CONFERENCE_ORDER, (data: IDataAlertConferenceOrder) => {
      setModalAlertConferenceOrder({
        data,
        open: true,
      })
    })

    socket.on(enEvent.PENDING_CONFERENCE_ORDER_RESOLVED, (data: any) => {
      setModalAlertConferenceOrder(initialStateModalAlertConferenceOrder)
    })

    socket.on(enEvent.PENDING_ORDER, (data: IDataAlertOrder) => {
      setModalAlertOrder({
        data,
        open: true,
      })
    })

    socket.on(enEvent.PENDING_ORDER_RESOLVED, (data: any) => {
      setModalAlertOrder(initialStateModalAlertOrder)
    })

    socket.on(enEvent.PENDING_FINANCE_VALIDATE_ORDER, (data: IDataAlertFinanceValidadeOrder) => {
      setModalAlertFinanceValidateOrder({
        data,
        open: true,
      })
    })

    socket.on(enEvent.PENDING_FINANCE_VALIDATE_ORDER_RESOLVED, (data: any) => {
      setModalAlertFinanceValidateOrder(initialStateModalAlertOrder)
    })

    return () => {
      socket.close()
    }
  }, [])

  return {
    confirmOrderBox,
    confirmBoxesQuantity,
    isConnected,
    conveyorBeltStatus,
    modalErrorRef,
    handleConveyorBeltStatus,
    conveyorBeltClientStatus,
    socket,
    modalAlertOrder,
    modalAlertInvoiceOrder,
    modalAlertConferenceOrder,
    modalAlertFinanceValidateOrder,
    tratedRoute,
    modalRouteNotTratament,
  }
}
