import { Box, Tooltip } from '@material-ui/core'
import { Button, ContainerPage } from 'components'
import { ReturnArea } from 'components/pages/Config/'
import { usePopup } from 'hooks/usePopup'
import { useEffect, useState } from 'react'
import { useStyles } from './styles'
import { IoAddSharp } from 'react-icons/io5'
import { useTableParams } from 'hooks/useTableParams'
import TableCustom from 'shared/TableCustom'
import { ModalConfirmTwoFactors } from './modalConfirm'
import { authenticationService } from 'services/authenticationService'

export const TwoFactors = () => {
  const classes = useStyles()
  const { addPopup } = usePopup()
  const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [internetConnectionOk, setInternetConnectionOk] = useState(true)
  const [createData, setCreateData] = useState({
    qrCodeUri: '',
    secretKey: '',
  })
  const { count, data, params, refresh, onChange, status } = useTableParams({
    service: (params: any) => authenticationService.getTwofactorsUsers(),
  })

  const checkInternetConnection = async () => {
    try {
      await fetch('https://www.google.com', { mode: 'no-cors' })
      setInternetConnectionOk(true)
    } catch (error) {
      setInternetConnectionOk(false)
      addPopup({
        type: 'error',
        title: 'Verificação de internet no CD executada.',
        description: `O CD está sem internet.`,
      })
    }
  }

  const handleNewTwoFactor = async () => {
    try {
      setIsLoading(true)
      const response = await authenticationService.postTwofactorsUsers()
      setCreateData({ qrCodeUri: response?.qr_code_uri, secretKey: response?.secret_key })
      setModalCreateIsOpen(true)
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao criar autenticação.',
        description: error?.message || error?.msg || 'Contate o time de tech',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleConfirmTwoFactor = async (secretKey: string) => {
    try {
      setIsLoading(true)
      await authenticationService.patchTwofactorsUsers(secretKey)
      addPopup({
        type: 'success',
        title: 'Autenticação criada com sucesso',
      })
      handleCloseModalCreate()
      refresh()
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao confirmar autenticação.',
        description: error?.message || error?.msg || 'Contate o time de tech',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteTwoFactor = async (twoFactorSecret: string) => {
    try {
      setIsLoading(true)
      const response = await authenticationService.deleteTwofactorsUsers(twoFactorSecret)
      addPopup({
        type: 'success',
        title: 'Autenticação excluída com sucesso',
        description: response?.message,
      })
      refresh()
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao excluir autenticação.',
        description: error?.message || error?.msg || 'Contate o time de tech',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleCloseModalCreate = () => {
    setModalCreateIsOpen(false)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkInternetConnection()
    }, 60000)

    checkInternetConnection()
    return () => clearInterval(intervalId)
  }, [])

  return (
    <>
      <ContainerPage>
        <ReturnArea title="Dois fatores" />

        <Box className={classes.boxAlign}>
          <Tooltip
            title="Só é possível criar por aqui quando o CD estiver sem internet. Lembre-se:
            ao criar por aqui, a autenticação só vai funcionar até que a internet volte. Você
            deve criar a sua autenticação permanente pelo Abacate assim que a internet do CD voltar."
          >
            <span style={{ display: 'inline-block', marginLeft: 'auto' }}>
              <Button
                onClick={() => handleNewTwoFactor()}
                className={classes.configs}
                style={{ textTransform: 'none', marginTop: '2rem', marginLeft: 'auto' }}
                variant="contained"
                disabled={isLoading || internetConnectionOk}
              >
                <IoAddSharp size={'2rem'} style={{ marginRight: '0.6rem' }} />
                Criar autenticação pra mim
              </Button>
            </span>
          </Tooltip>
        </Box>

        <TableCustom
          columns={[
            {
              style: { fontSize: '1.2rem' },
              field: 'username',
              label: 'Usuário',
            },
          ]}
          data={data}
          params={params}
          onChange={onChange}
          refresh={refresh}
          count={count}
          status={status}
          rowStyle={() => ({
            fontSize: '1.1rem',
          })}
        />
      </ContainerPage>
      <ModalConfirmTwoFactors
        handleClose={handleCloseModalCreate}
        data={createData}
        handleConfirm={handleConfirmTwoFactor}
        open={modalCreateIsOpen}
        isLoading={isLoading}
      />
    </>
  )
}

export default TwoFactors
