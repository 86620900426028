import { InfoContainer, InfoText } from './styles'

interface IProps {
  data: {
    [keyof in string]: {
      text: string | number
      useStrong?: boolean
    }
  }
}

const InfoCard = ({ data, ...rest }: IProps) => (
  <InfoContainer style={{ width: '100%' }} {...rest}>
    {Object.entries(data).map(([key, data]) => {
      const children = {
        true: <strong>{data?.text}</strong>,
        false: <span>{data?.text}</span>,
      }[String(!!data?.useStrong)]

      return (
        <InfoText key={key}>
          {key}: {children}
        </InfoText>
      )
    })}
  </InfoContainer>
)

export default InfoCard
