import { Box, Divider, Typography } from '@material-ui/core'
import { Button } from 'components'
import { usePopup } from 'hooks/usePopup'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'shared'

import { useStyles } from './styles'
import logoIfood from 'assets/icons/logo-ifood.png'
import { IoStorefrontSharp, IoHome } from 'react-icons/io5'
import { FiAlertTriangle } from 'react-icons/fi'
import { FaTruckLoading } from 'react-icons/fa'
import {
  ISeparationGroupConfig,
  ISeparationGroupConfigRequest,
} from 'interfaces/ISeparationGroupService'
import {
  MdOutlinePrecisionManufacturing,
  MdPendingActions,
  MdQrCodeScanner,
  MdScale,
} from 'react-icons/md'
import CheckboxConfig from 'components/pages/Config/CheckboxConfig'
import { BsBoxSeam, BsCheck2All } from 'react-icons/bs'
import { ContainerCheckboxs } from '../styles'
import ModalStepScanner, { IStepsSelected } from '../ModalStepScanner'
import { ModalConfirmTwoFactors } from './ModalConfirmTwoFactors'

interface IProps extends ISeparationGroupConfig {
  isOpen: boolean
  handleCloseModal: () => void
  submitUpdateSeparationGroup: (
    params: ISeparationGroupConfigRequest,
    type: 'create' | 'update',
    dynamicKey: string,
  ) => Promise<void>
  submitDeleteSeparationGroup: (separationGroup: number) => Promise<void>
  marketsNumber: number[]
}

export const SeparationGroupConfig = ({
  isOpen,
  handleCloseModal,
  submitUpdateSeparationGroup,
  submitDeleteSeparationGroup,
  marketsNumber,
  ...rest
}: IProps) => {
  const classes = useStyles()
  const {
    separation_group,
    cam_scanner,
    receive_pj_orders,
    receive_pf_orders,
    receive_ka_orders,
    receive_critical_orders,
    receive_ifood_orders,
    receive_dinamic_price_orders,
    separation_group_commands,
    has_conference,
    infrared_scanner,
    has_count_by_step,
    steps_without_scanner,
    market_number,
  } = rest
  const { addPopup } = usePopup()
  const [isLoading, setIsLoading] = useState(false)
  const [isPJ, setIsPJ] = useState(receive_pj_orders)
  const [isPF, setIsPF] = useState(receive_pf_orders)
  const [isKA, setIsKA] = useState(receive_ka_orders)
  const [isCritical, setIsCritical] = useState(receive_critical_orders)
  const [isIfood, setIsIfood] = useState(receive_ifood_orders)
  const [isDinamicPrice, setIsDinamicPrice] = useState(receive_dinamic_price_orders)
  const [isDirected, setIsDirected] = useState(separation_group_commands)
  const [isCamScanner, setIsCamScanner] = useState(cam_scanner)
  const [hasConference, setHasConference] = useState(has_conference)
  const [isInfraredScanner, setIsInfraredScanner] = useState(infrared_scanner)
  const [hasCountByStep, setHasCountByStep] = useState(has_count_by_step)
  const [stepsWithoutScanner, setStepsWithoutScanner] = useState(steps_without_scanner || [])
  const [marketNumber, setMarketNumber] = useState(market_number)
  const [isNecessaryTwoFactorsModal, setIsNecessaryTwoFactorsModal] = useState(false)
  const [isOpenTwoFactorsModal, setIsOpenTwoFactorsModal] = useState(false)
  const [scannerInBegin, setScannerInBegin] = useState(true)

  const [modalStepScanner, setModalStepScanner] = useState({
    open: false,
    callback: null as any as (steps: IStepsSelected) => void,
  })
  const withoutReceiveOrderType =
    !isPJ && !isPF && !isKA && !isCritical && !isIfood && !isDinamicPrice && isDirected

  const handleSubmitForm = async (dynamicKey: string) => {
    try {
      setIsLoading(true)
      await submitUpdateSeparationGroup(
        {
          separationGroup: separation_group,
          camScanner: isCamScanner,
          fastTrack: true,
          pjOrders: isPJ,
          pfOrders: isPF,
          kaOrders: isKA,
          criticalOrders: isCritical,
          ifoodOrders: isIfood,
          dinamicPriceOrders: isDinamicPrice,
          separationGroupCommands: isDirected,
          hasConference,
          infraredScanner: isInfraredScanner,
          hasCountByStep,
          stepsWithoutScanner,
          marketNumber,
        },
        'update',
        dynamicKey,
      )

      handleCloseModal()
      setIsOpenTwoFactorsModal(false)
      setIsNecessaryTwoFactorsModal(false)
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro para atualizar grupo de separação',
        description: 'Contate o time de tech',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const titleGroupSeparationEdit = () => {
    const tituloModal = 'Configurar grupo de separação ' + separation_group

    return <p>{tituloModal}</p>
  }

  const handleInfraredScanner = (value: boolean) => {
    if (value && isCamScanner) {
      setIsCamScanner(false)
      setIsInfraredScanner(true)
      setIsNecessaryTwoFactorsModal(false)
      return
    }
    if (scannerInBegin && !value) {
      setIsNecessaryTwoFactorsModal(true)
    }
    if (scannerInBegin && value) {
      setIsNecessaryTwoFactorsModal(false)
    }
    setIsInfraredScanner(value)
  }

  const handleCamScanner = (value: boolean) => {
    if (value && isInfraredScanner) {
      setIsInfraredScanner(false)
      setIsCamScanner(true)
      setIsNecessaryTwoFactorsModal(false)
      return
    }
    if (scannerInBegin && !value) {
      setIsNecessaryTwoFactorsModal(true)
    }
    if (scannerInBegin && value) {
      setIsNecessaryTwoFactorsModal(false)
    }
    setIsCamScanner(value)
  }

  const onOpenModalStepScanner = () => {
    setModalStepScanner({
      open: true,
      callback: (steps: IStepsSelected) => {
        const stepsNotSelected = steps.filter(step => !step[Number(Object.keys(step)[0])])
        setStepsWithoutScanner(stepsNotSelected.map(step => Number(Object.keys(step)[0])))
        setModalStepScanner({
          open: false,
          callback: () => null as any as (steps: IStepsSelected) => void,
        })
      },
    })
  }

  const handleVerifyChangeSteps = () => {
    if (
      steps_without_scanner &&
      stepsWithoutScanner &&
      stepsWithoutScanner.length > steps_without_scanner.length
    ) {
      setIsNecessaryTwoFactorsModal(true)
      return
    }
    if (
      steps_without_scanner &&
      stepsWithoutScanner &&
      stepsWithoutScanner.length === steps_without_scanner.length
    ) {
      setIsNecessaryTwoFactorsModal(false)
    }
  }
  const handleDirectedChange = () => {
    setIsDirected(!isDirected)
  }

  const handleInverseDirectedChange = () => {
    setIsDirected(!isDirected)
  }

  const handleClose = () => {
    setIsNecessaryTwoFactorsModal(false)
    handleCloseModal()
  }

  const handleVerifySubmitForm = () => {
    if (isNecessaryTwoFactorsModal) {
      setIsOpenTwoFactorsModal(true)
      return
    }

    handleSubmitForm('')
  }

  const handleCloseModalTwoFactors = () => {
    setIsOpenTwoFactorsModal(false)
  }

  const handleDeleteSeparationGroup = async () => {
    try {
      setIsLoading(true)
      await submitDeleteSeparationGroup(Number(separation_group))
      handleCloseModal()
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro para atualizar grupo de separação',
        description: 'Contate o time de tech',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsPJ(receive_pj_orders)
    setIsPF(receive_pf_orders)
    setIsKA(receive_ka_orders)
    setIsIfood(receive_ifood_orders)
    setIsCritical(receive_critical_orders)
    setIsDinamicPrice(receive_dinamic_price_orders)
    setIsDirected(separation_group_commands || false)
    setIsCamScanner(cam_scanner)
    setHasConference(has_conference)
    setIsInfraredScanner(infrared_scanner)
    setHasCountByStep(has_count_by_step)
    setStepsWithoutScanner(steps_without_scanner || [])
    setMarketNumber(market_number)

    const scannerBegin = cam_scanner || infrared_scanner
    setScannerInBegin(scannerBegin)
  }, [separation_group])

  useEffect(() => {
    handleVerifyChangeSteps()
  }, [stepsWithoutScanner])

  return (
    <>
      <Modal title={titleGroupSeparationEdit()} handleClose={handleClose} open={isOpen}>
        <Typography className={classes.separationGroupNumber}>
          Fala aí se é você ou o grupo que vai escolher decidir quais pedidos buscar
        </Typography>
        <Box>
          <ContainerCheckboxs>
            <CheckboxConfig
              checked={isDirected}
              onChange={handleDirectedChange}
              text="O grupo manda"
              icon={MdOutlinePrecisionManufacturing}
            />
            <CheckboxConfig
              checked={!isDirected}
              onChange={handleInverseDirectedChange}
              text="Eu que mando"
              icon={MdPendingActions}
            />
          </ContainerCheckboxs>
        </Box>
        <Divider className={classes.divider} />
        <ContainerCheckboxs>
          <CheckboxConfig
            checked={isPJ}
            onChange={setIsPJ}
            text="Pedidos PJ"
            icon={IoStorefrontSharp}
            disable={!isDirected}
          />
          <CheckboxConfig
            checked={isCritical}
            onChange={setIsCritical}
            text="Pedidos Críticos"
            icon={FiAlertTriangle}
            disable={!isDirected}
            isRed
          />
          <CheckboxConfig
            checked={isPF}
            onChange={setIsPF}
            disable={!isDirected}
            text="Pedidos PF"
            icon={IoHome}
          />
          <CheckboxConfig
            checked={isIfood}
            onChange={setIsIfood}
            text="Pedidos IFood"
            disable={!isDirected}
            image={logoIfood}
          />
          <CheckboxConfig
            checked={isKA}
            onChange={setIsKA}
            disable={!isDirected}
            text="Pedidos KA"
            icon={FaTruckLoading}
          />
          <CheckboxConfig
            checked={isDinamicPrice}
            onChange={setIsDinamicPrice}
            text="Pedidos Extra Order"
            disable={!isDirected}
            icon={MdScale}
          />
        </ContainerCheckboxs>
        <Divider className={classes.divider} />
        <ContainerCheckboxs>
          <CheckboxConfig
            checked={isCamScanner}
            onChange={handleCamScanner}
            text="Leitura câmera"
            icon={MdQrCodeScanner}
          />
          <CheckboxConfig
            checked={hasConference}
            onChange={setHasConference}
            text="Revisão"
            icon={BsCheck2All}
          />
          <CheckboxConfig
            checked={isInfraredScanner}
            onChange={handleInfraredScanner}
            text="Leitura infra-vermelho"
            icon={MdQrCodeScanner}
          />
          <CheckboxConfig
            checked={hasCountByStep}
            onChange={setHasCountByStep}
            text="Contagem por etapa"
            icon={BsBoxSeam}
          />
        </ContainerCheckboxs>
        <Divider className={classes.divider} />
        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
          <Typography className={classes.separationGroupNumber} style={{ marginBottom: '0.5rem' }}>
            Confere aí se é esse o mercado ao qual esse grupo pertence:
          </Typography>
          <select
            value={marketNumber}
            onChange={event => setMarketNumber(Number(event.target.value))}
            className={classes.select}
          >
            {marketsNumber.map((market, index) => (
              <option key={index} value={market}>
                Mercado {market}
              </option>
            ))}
          </select>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            className={classes.buttonConfigs}
            onClick={onOpenModalStepScanner}
            style={{ marginLeft: 0 }}
            disabled={(!isCamScanner && !isInfraredScanner) || isLoading}
          >
            <Typography className={classes.buttonText}>Etapas</Typography>
          </Button>
          <Button
            variant="contained"
            className={classes.buttonConfigsDelete}
            onClick={handleDeleteSeparationGroup}
            disabled={isLoading}
          >
            <Typography className={classes.buttonText}>Excluir</Typography>
          </Button>
          <Button
            variant="contained"
            className={classes.buttonConfigs}
            onClick={handleVerifySubmitForm}
            disabled={withoutReceiveOrderType || isLoading}
          >
            <Typography className={classes.buttonText}>Salvar</Typography>
          </Button>
        </Box>
      </Modal>
      <ModalStepScanner
        open={modalStepScanner.open}
        onConfirm={modalStepScanner.callback}
        stepsWithoutScanner={stepsWithoutScanner}
        onClose={() => {
          setModalStepScanner({
            open: false,
            callback: () => null as any as (steps: IStepsSelected) => void,
          })
        }}
      />
      <ModalConfirmTwoFactors
        handleClose={handleCloseModalTwoFactors}
        handleConfirm={handleSubmitForm}
        open={isOpenTwoFactorsModal}
        isLoading={isLoading}
      />
    </>
  )
}
