import {
  Modal as ModalMui,
  Container as ContainerMui,
  styled,
  Box,
  Button,
  LinearProgress,
} from '@material-ui/core'

export const Modal = styled(ModalMui)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}))

export const Container = styled(ContainerMui)(({ theme }) => ({
  width: '100%',
  margin: 'auto 2%',
  background: '#FFFFFF',
  padding: '1.875rem',
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100px',
  gap: '16px',
  position: 'relative',

  [theme.breakpoints.up('md')]: {
    width: '60%',
    maxWidth: '600px',
  },

  [theme.breakpoints.up('lg')]: {
    width: '40%',
    maxWidth: '700px',
  },
}))

export const Title = styled('h2')(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 500,
  color: '#353535',
}))

export const InfoCostumer = styled(Box)(({ theme }) => ({
  background: '#F5F8F3',
  padding: '1rem',
  borderRadius: '1rem',
  fontSize: '1rem',
  width: '100%',
  '& p': {
    color: '#757575',
    '&:not(:first-child)': {
      marginTop: '8px',
    },
    '& strong': {
      marginLeft: '8px',
    },
  },
  '& p:first-child strong': {
    color: '#353535',
  },
}))

export const TextConfirmRelease = styled('p')(({ theme }) => ({
  fontSize: '1rem',
  color: '#757575',
  width: '100%',
}))

const ButtonBase = styled(Button)({
  width: '100%',
  borderRadius: '6px',
})

export const ContainerButtons = styled(Box)({
  display: 'flex',
  gap: '1rem',
  width: '100%',
})

export const ButtonCancel = styled(ButtonBase)({
  background: '#FFFFFF',
  border: '1px solid #757575',
  color: '#757575',
})

export const ButtonConfirm = styled(ButtonBase)({
  background: '#89BD23',
  color: '#FFFFFF',
})

export const Loading = styled(LinearProgress)(
  ({
    loading,
    colorBackground,
    loadingColor,
  }: {
    loading: boolean
    colorBackground?: string
    loadingColor?: string
  }) => ({
    opacity: loading ? 1 : 0,
    height: '5px',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    borderRadius: '0.5rem 0.5rem 0 0',
    backgroundColor: colorBackground || '#89BD23',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: loadingColor || '#89BD23',
    },
  }),
)
