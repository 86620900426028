import { TextField } from '@material-ui/core'
import {
  ButtonCancel,
  ButtonConfirm,
  Container,
  ContainerButtons,
  InfoCostumer,
  Loading,
  Modal,
  Title,
} from './styles'
import { useState } from 'react'

interface IModalConfirmTwoFactors {
  open: boolean
  isLoading: boolean
  handleClose: () => void
  handleConfirm: (dynamicKey: string) => void
}

export const ModalConfirmTwoFactors = ({
  handleClose,
  handleConfirm,
  open,
  isLoading,
}: IModalConfirmTwoFactors) => {
  const [dynamicKey, setDynamicKey] = useState('')

  const onChangeInputNewQuantity = (e: any) => {
    setDynamicKey(e.target.value)
  }

  const handleButtonConfirm = (dynamicKey: string) => {
    setDynamicKey('')
    handleConfirm(dynamicKey)
  }

  const handleButtonClose = () => {
    setDynamicKey('')
    handleClose()
  }

  return (
    <>
      <Modal open={open}>
        <Container>
          <Loading loading={isLoading} />
          <Title>Verificação em dois fatores</Title>
          <InfoCostumer>
            <p>
              <strong>1°. </strong>Acesse o seu aplicativo de autenticação.
            </p>
            <p>
              <strong>2°. </strong>Procure a autenticação com nome Frexco.
            </p>
            <p>
              <strong>3°. </strong>Digite abaixo a código autenticador.
            </p>
            <p>
              <strong>4°. </strong>Confirme e aguarde a validação.
            </p>
          </InfoCostumer>
          <TextField
            label="Digite o código de autenticação"
            variant="outlined"
            style={{ width: '100%', margin: '16px 0 4px 0' }}
            type="string"
            onChange={onChangeInputNewQuantity}
          />
          <ContainerButtons>
            <ButtonCancel onClick={handleButtonClose} disabled={isLoading}>
              Cancelar
            </ButtonCancel>
            <ButtonConfirm
              onClick={() => handleButtonConfirm(dynamicKey)}
              disabled={isLoading || !dynamicKey}
            >
              Confirmar
            </ButtonConfirm>
          </ContainerButtons>
        </Container>
      </Modal>
    </>
  )
}
