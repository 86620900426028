import { Grid } from '@material-ui/core'
import separationGroup from 'assets/images/separation-group.svg'
import separationImg from 'assets/images/separation-white.svg'
import { CardConfig, ContainerPage, TitlePage } from 'components'
import { useConfig } from 'hooks/useConfig/Index'
import { enRules } from 'interfaces/IUser'
import { useEffect } from 'react'
import { AiFillPrinter } from 'react-icons/ai'
import { BsFillBarChartFill } from 'react-icons/bs'
import { FaBalanceScale, FaPallet } from 'react-icons/fa'
import { GiStairsGoal } from 'react-icons/gi'
import { MdSystemSecurityUpdateGood } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { IState } from 'store'

const Config = () => {
  const navegate = useNavigate()
  const { getSetting } = useConfig()
  const { user } = useSelector<IState, IState>(state => state)
  const handleNavegateTrack = () => {
    navegate('./grupos')
  }

  const handleNavegateSeparation = () => {
    navegate('./separacao')
  }

  const handleNavegateSeparator = () => {
    navegate('./separadores')
  }

  const handleNavegateGoals = () => {
    navegate('./metas')
  }

  const handleNavegateHighOrders = () => {
    navegate('./alta-pedidos')
  }

  const handleNavegatePalletizing = () => {
    navegate('./paletizacao')
  }

  const handleNavegatePrinters = () => {
    navegate('./impressoras')
  }

  const handleNavegateTwoFactors = () => {
    navegate('./dois-fatores')
  }

  useEffect(() => {
    getSetting()
  }, [])

  return (
    <>
      <ContainerPage>
        <TitlePage>Configurações</TitlePage>

        <Grid container style={{ gap: '2rem' }}>
          <Grid item onClick={handleNavegateTrack}>
            <CardConfig
              image={separationGroup}
              title="Grupos de separação"
              subTitle="Aqui cê adiciona, remove ou configura os grupos de separação"
            />
          </Grid>

          <Grid item onClick={handleNavegateSeparation}>
            <CardConfig
              Icon={FaBalanceScale}
              title="SKUs"
              subTitle="Aqui cê configura o extra order e a ordem de separação dos SKUs"
            />
          </Grid>

          <Grid item onClick={handleNavegateSeparator}>
            <CardConfig
              image={separationImg}
              title="Separadores"
              subTitle="Aqui cê configura a quantidade de separadores atuantes"
            />
          </Grid>

          <Grid item onClick={handleNavegateGoals}>
            <CardConfig
              Icon={GiStairsGoal}
              title="Metas"
              subTitle="Aqui cê configura a meta de separações do dia"
            />
          </Grid>
          <Grid item onClick={handleNavegatePalletizing}>
            <CardConfig
              Icon={FaPallet}
              title="Paletização"
              subTitle="Aqui cê aponta o peso e caixas de atacado mínimos para que o separador inicie o pedido com palete"
            />
          </Grid>
          <Grid item onClick={handleNavegatePrinters}>
            <CardConfig
              Icon={AiFillPrinter}
              title="Impressoras"
              subTitle="Aqui cê altera para qual impressora uma determinada rota será impressa"
            />
          </Grid>
          <Grid item onClick={handleNavegateTwoFactors}>
            {user.userPermissions.includes(enRules.SeparationTwofactorsManagement) && (
              <CardConfig
                Icon={MdSystemSecurityUpdateGood}
                title="Autenticação (2FA)"
                subTitle="Aqui cê gerencia a autenticação de dois fatores."
              />
            )}
          </Grid>
        </Grid>
      </ContainerPage>
    </>
  )
}

export default Config
