import { Box, Button, styled } from '@material-ui/core'

export const Container = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(10, 1fr)',
  gap: '0.5rem',
  padding: '1rem',
  backgroundColor: '#f5f5f5',
  borderRadius: '8px',
})

export const Key = styled(Button)({
  padding: '0.3rem',
  fontSize: '1.2rem',
  border: 'none',
  borderRadius: '4px',
  backgroundColor: 'white',
  cursor: 'pointer',
  transition: 'background-color 0.2s',

  '&:hover': {
    backgroundColor: '#e0e0e0',
  },

  '&:active': {
    backgroundColor: '#bdbdbd',
  },
})
