import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import {
  ButtonCheckbox,
  ButtonConfirm,
  Checkbox,
  ContainerList,
  Divider,
  ModalContainer,
  PaperContainer,
  SubTitle,
  TextCheckbox,
  Title,
  useStyles,
} from './styles'
import { IState } from 'store'
import { ISettingsRequest } from 'services/settingsService'
import { usePopup } from 'hooks/usePopup'

export type IStepsSelected = { [key: number]: boolean }[]

interface IProps {
  open: boolean
  onConfirm: (steps: { [key: number]: boolean }[]) => void
  stepsWithoutScanner: number[]
  onClose: () => void
}

const ModalStepScanner = ({ open, onConfirm, stepsWithoutScanner, onClose }: IProps) => {
  const { separations } = useSelector<IState, ISettingsRequest>(state => state.settings)
  const { addPopup } = usePopup()
  const classes = useStyles()

  const arraySeparations =
    separations > 0 ? Array.from({ length: separations }, (_, i) => i + 1) : []

  const [controlSelected, setControlSelected] = useState<{
    all: boolean
    steps: { [key: number]: boolean }
  }>({
    all: false,
    steps: {},
  })

  const initializeSteps = () => {
    const steps = arraySeparations.reduce<{ [key: number]: boolean }>((acc, step) => {
      acc[step] = !stepsWithoutScanner.includes(step)
      return acc
    }, {})

    const allSelected = Object.values(steps).every(value => value)
    setControlSelected({ all: allSelected, steps })
  }

  useEffect(() => {
    if (arraySeparations.length > 0) {
      initializeSteps()
    }
  }, [separations, stepsWithoutScanner])

  const onClickSelectAll = () => {
    const newAll = !controlSelected.all
    const newSteps = arraySeparations.reduce<{ [key: number]: boolean }>((acc, step) => {
      acc[step] = newAll
      return acc
    }, {})

    setControlSelected({ all: newAll, steps: newSteps })
  }

  const onClickSelectStep = (step: number) => {
    setControlSelected(state => {
      const newSteps = { ...state.steps, [step]: !state.steps[step] }
      const allSelected = Object.values(newSteps).every(value => value)
      return { all: allSelected, steps: newSteps }
    })
  }

  const onClickConfirm = () => {
    if (Object.values(controlSelected.steps).every(value => !value)) {
      addPopup({ title: 'Marque pelo menos uma etapa', type: 'error' })
      return
    }

    const formatted = Object.entries(controlSelected.steps).map(([key, value]) => ({
      [Number(key)]: value,
    }))

    onConfirm(formatted)
  }

  return (
    <ModalContainer open={open} onClose={onClose}>
      <PaperContainer className={classes.animation}>
        <Title>Em quais etapas será obrigatório usar o scanner?</Title>
        <SubTitle style={{ margin: '16px 0' }}>
          Selecione as etapas em que será obrigatório usar o scanner
        </SubTitle>
        <ButtonCheckbox onClick={onClickSelectAll}>
          <Checkbox checked={controlSelected.all} />
          <TextCheckbox>Todas as etapas</TextCheckbox>
        </ButtonCheckbox>
        <Divider style={{ margin: '1rem 0 ' }} />
        <ContainerList style={{ marginBottom: '16px' }}>
          {arraySeparations.map(step => (
            <ButtonCheckbox
              key={step}
              onClick={() => onClickSelectStep(step)}
              style={{ marginBottom: '8px' }}
            >
              <Checkbox checked={controlSelected.steps[step] ?? false} />
              <TextCheckbox>Etapa {step}</TextCheckbox>
            </ButtonCheckbox>
          ))}
        </ContainerList>
        <ButtonConfirm onClick={onClickConfirm}>Confirmar</ButtonConfirm>
      </PaperContainer>
    </ModalContainer>
  )
}

export default ModalStepScanner
