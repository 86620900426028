import { makeStyles } from '@material-ui/core'
import { transparentize } from 'polished'

export const useStyles = makeStyles(theme => ({
  subTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    color: '#414141',
    textAlign: 'center',
  },
  buttonConfigs: {
    display: 'flex',
    fontSize: '1rem',
    background: '#618619',
    position: 'absolute',
    top: '14rem',
    right: '2rem',
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    textAlign: 'center',
    fontWeight: 500,
    lineHeight: '5rem',
    width: '12rem',
  },
  dividerButton: {
    marginLeft: '0.05rem',
    background: '#ccc',
    border: '1px solid #ccc',
    height: '1.5rem',
    [theme.breakpoints.between(0, 1050)]: {
      margin: '0 0rem',
    },
  },
  title: {
    textAlign: 'left',
    color: '#88BC23',
    fontSize: '1.3rem',
    marginBottom: '1.4rem',
  },
}))
