import { RiDeleteBack2Fill } from 'react-icons/ri'

import { Container, Key } from './styles'

interface IVirtualKeyboardLettersProps {
  onClick: (value: string) => void
}

const VirtualKeyboardLetters = ({ onClick }: IVirtualKeyboardLettersProps) => {
  const letters = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',
    'Q',
    'W',
    'E',
    'R',
    'T',
    'Y',
    'U',
    'I',
    'O',
    'P',
    'A',
    'S',
    'D',
    'F',
    'G',
    'H',
    'J',
    'K',
    'L',
    'Z',
    'X',
    'C',
    'V',
    'B',
    'N',
    'M',
    'delete',
  ]

  return (
    <Container>
      {letters.map((letter, index) => (
        <Key key={index} onClick={() => onClick(letter)}>
          {letter === 'delete' ? (
            <RiDeleteBack2Fill
              size={25}
              color="#f33c3c"
              style={{
                display: 'block',
                margin: '0 auto',
              }}
            />
          ) : (
            letter
          )}
        </Key>
      ))}
    </Container>
  )
}

export default VirtualKeyboardLetters
