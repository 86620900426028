import { TableRow as TableRowMui, TableRowProps } from '@material-ui/core'
import { memo, ReactNode } from 'react'

interface Props extends TableRowProps {
  children: ReactNode
}

const TableRowComponent = (props: Props) => {
  const { children } = props

  return <TableRowMui {...props}>{children}</TableRowMui>
}

const TableRow = memo(TableRowComponent)

export default TableRow
