import { Box, Button, Modal, TextField, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import { usePopup } from 'hooks/usePopup'
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef, useState } from 'react'
import { ITruckLoading, truckLoadingService } from 'services/truckLoadingService'

import { InfoCard } from '../../../../shared'
import VirtualKeyboardLetters from '../VirtualKeyboardLetters'

interface IModalTruckLoadingProps {
  open: boolean
  onClose: () => void
  activeLoading: ITruckLoading | null
  errorMessage?: string
  onSuccess: () => void
}

export interface IModalTruckLoadingRef {
  isOpen: boolean
}

const Component: ForwardRefRenderFunction<IModalTruckLoadingRef, IModalTruckLoadingProps> = (
  { open, onClose, activeLoading, errorMessage, onSuccess },
  ref,
) => {
  const [licensePlate, setLicensePlate] = useState('')
  const { addPopup } = usePopup()
  const isOpenRef = useRef<boolean>(false)

  useImperativeHandle(ref, () => ({
    isOpen: isOpenRef.current,
  }))

  const handleSubmit = async () => {
    try {
      if (activeLoading) {
        await truckLoadingService.finish(activeLoading.license_plate)
        addPopup({ type: 'success', title: 'Carregamento finalizado com sucesso!' })
      } else {
        await truckLoadingService.start(licensePlate)
        addPopup({ type: 'success', title: 'Carregamento iniciado com sucesso!' })
        onClose()
      }
      onSuccess()
      setLicensePlate('')
    } catch (error: any) {
      addPopup({
        type: 'error',
        title: 'Erro ao processar carregamento',
        description: error?.message || 'Ocorreu um erro inesperado',
      })
    }
  }

  const onClickKeyboard = (value: string) => {
    if (value === 'delete') {
      setLicensePlate(state => state.slice(0, -1))
      return
    }
    if (value === 'submit') {
      handleSubmit()
      return
    }
    if (licensePlate.length < 10) {
      setLicensePlate(state => state + value)
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        style={{
          backgroundColor: 'white',
          padding: '2rem',
          borderRadius: '8px',
          minWidth: '40rem',
        }}
      >
        <Typography variant="h6" gutterBottom>
          {activeLoading ? 'Finalizar Carregamento' : 'Iniciar Carregamento'}
        </Typography>

        {errorMessage && (
          <Typography variant="body2" color="error" gutterBottom>
            {errorMessage}
          </Typography>
        )}

        {activeLoading ? (
          <InfoCard
            data={{
              Placa: { text: activeLoading.license_plate, useStrong: true },
              'Data e hora de início': {
                text: format(new Date(activeLoading.start_time), "dd/MM/yyyy 'às' HH:mm:ss"),
              },
            }}
          />
        ) : (
          <>
            <Box>
              <TextField
                fullWidth
                label="Placa do Veículo"
                value={licensePlate}
                onChange={e => setLicensePlate(e.target.value.toUpperCase())}
                margin="normal"
                variant="outlined"
                disabled
              />
              <Box style={{ height: '100%' }}>
                <VirtualKeyboardLetters onClick={onClickKeyboard} />
              </Box>
            </Box>
          </>
        )}

        <Box
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', marginTop: '1rem' }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={activeLoading ? false : !licensePlate || licensePlate.length <= 6}
          >
            {activeLoading ? 'Finalizar' : 'Iniciar'}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export const ModalTruckLoading = forwardRef(Component)
